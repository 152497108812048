import React, { ReactChild, CSSProperties } from "react";

interface IProps {
  children: ReactChild;
}

const style: CSSProperties = {
  width: "100%",
  flexDirection: "column",
  display: "flex",
  flex: 1,
  // background: 'white',
  padding: "16px",
  // boxShadow: "0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)",
  // borderRadius: '12px',
  maxWidth: 1280,
  margin: "0 auto",
};

function AppWrapper(props: IProps) {
  return <div style={style}>{props.children}</div>;
}

export default AppWrapper;
