import React, { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Layout, Input, Button, message, Form } from "antd";
import { RouteProps } from "react-router";
import firebase from "firebase/app";
import "firebase/auth";

import Loading from "../Base/Loading";
import Logo from "../Base/Logo";
import { PaperWrapper } from "../Base/Layout";
import GoogleSignin from "./GoogleSignin";

async function login(email: string) {
  try {
    await firebase.auth().sendSignInLinkToEmail(email, {
      url: window.location.href,
      handleCodeInApp: true,
    });

    window.localStorage.setItem("emailForSignIn", email);
    message.success("Check your email address for login link");
  } catch (error) {
    firebase.auth().signOut();
    throw error;
  }
}

function Login(props: RouteProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await login(values.email);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.message) {
        message.error(e.message, 3);
      }
    }
  };

  React.useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email: string = window.localStorage.getItem(
        "emailForSignIn"
      ) as string;
      if (!email) {
        email = window.prompt(
          "Please provide your email for confirmation"
        ) as string;
      }
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          window.localStorage.removeItem("emailForSignIn");
          console.log(result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100vh", justifyContent: "center" }}>
      {loading && <Loading />}
      <Form
        hideRequiredMark
        onFinish={handleSubmit}
        initialValues={{
          email: "",
        }}
        style={{
          padding: 16,
          marginBottom: 80,
        }}
      >
        <Logo style={{ maxWidth: 128, margin: "0 auto" }} />
        <div style={{ height: 16 }}></div>
        <PaperWrapper style={{ maxWidth: 350 }}>
          <Form.Item
            label="Email"
            name="email"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              prefix={<UserOutlined style={{ fontSize: 13 }} />}
              placeholder="Email.."
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
            >
              Log in
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <GoogleSignin />
          </Form.Item>
        </PaperWrapper>
      </Form>
    </Layout>
  );
}

export default Login;
