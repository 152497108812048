export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDIXtndyc9d-7FKwhldXtN60GDzmND715Y",
  authDomain: "alpine-crew.firebaseapp.com",
  databaseURL: "https://alpine-crew.firebaseio.com",
  projectId: "alpine-crew",
  storageBucket: "alpine-crew.appspot.com",
  messagingSenderId: "722566271518",
  appId: "1:722566271518:web:c82a69a4534ad8f53f940f",
  measurementId: "G-5H7YFJEPHE"
};

export const DEFAULT_REGION: string = "europe-west1"; // TODO
