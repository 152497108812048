import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Switch } from "react-router-dom";
import { GuestRoute, RoleRoute, Login } from "./components/Auth";
import { initFirebase } from "./config/firebase";
import { ConfigProvider } from "antd";
import { getConfig } from "./config";

import "./index.less";

import enUS from "antd/lib/locale-provider/en_US";

initFirebase();

ReactDOM.render(
  <ConfigProvider locale={enUS}>
    {getConfig().IS_DEV && (
      <React.Fragment>
        <div
          style={{
            background: "#5AC66B",
            padding: "16px 24px",
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <span
            style={{
              display: "block",
              textAlign: "center",
              color: "#272038",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            DEVELOPMENT MODE
          </span>
        </div>
        <div style={{ height: 59 }}></div>
      </React.Fragment>
    )}
    <BrowserRouter>
      <Switch>
        <GuestRoute
          role={["admin"]}
          path={"/login"}
          component={Login}
          exact={true}
        />
        <RoleRoute role={["admin"]} path={"/"} component={App} />
      </Switch>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
