import React, { useState, useEffect } from "react";
// import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface"
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IRouteConfig } from "../../../config/routes";
import { MenuTheme } from "antd/lib/menu/MenuContext";

interface IProps extends RouteComponentProps {
  routes: IRouteConfig[];
  theme: MenuTheme;
}

function TopNavigation(props: IProps) {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys([props.location.pathname]);
  }, [props.location.pathname]);

  const handleMenuClick: MenuClickEventHandler = ({ key }) => {
    props.history.push(key as string);
    setSelectedKeys([key as string]);
  };

  return (
    <Menu
      theme={props.theme}
      mode="horizontal"
      selectedKeys={selectedKeys}
      defaultSelectedKeys={["/"]}
      onClick={handleMenuClick}
    >
      {props.routes.map(
        (route) =>
          !route.hidden && (
            <Menu.Item key={route.path}>
              {route.icon}
              <span>{route.title}</span>
            </Menu.Item>
          )
      )}
    </Menu>
  );
}

export default withRouter(TopNavigation);
