import React from "react";

import logoRound from "../../assets/images/logo.png";

function Logo(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  return (
    <div {...props}>
      <img
        src={logoRound}
        alt="Alpine Crew"
        style={{ width: "100%", height: "auto", display: "block" }}
      />
    </div>
  );
}

export default Logo;
