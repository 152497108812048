import React, { CSSProperties, ReactNode } from "react";

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
}

const style: CSSProperties = {
  width: "100%",
  background: "white",
  padding: "16px",
  boxShadow:
    "0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)",
  borderRadius: "12px",
  margin: "0 auto",
  maxWidth: 800,
};

function PaperWrapper(props: IProps) {
  return <div style={{ ...style, ...props.style }}>{props.children}</div>;
}

export default PaperWrapper;
