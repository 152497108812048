import React from "react";
import { GoogleOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import firebase from "firebase/app";
import "firebase/auth";

async function login() {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    if (error.message) {
      message.error(error.message, 3);
    }
  }
}

function GoogleSignin() {
  return (
    <div>
      <Button block icon={<GoogleOutlined />} onClick={login}>
        Google Signin
      </Button>
    </div>
  );
}

export default GoogleSignin;
