import React from "react";
import { Spin } from "antd";

const style = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: 48,
  background: "rgba(255,255,255,.25)",
  zIndex: 10000,
};

function Loading(props: any) {
  return (
    <div style={{ ...style, ...props.style }}>
      <Spin size="large" />
    </div>
  );
}

export default Loading;
