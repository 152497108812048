import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface"

import { withRouter, RouteComponentProps } from "react-router-dom";
import { IRouteConfig } from "../../../config/routes";
import { MenuTheme } from "antd/lib/menu/MenuContext";

interface IProps extends RouteComponentProps {
  routes: IRouteConfig[];
  theme: MenuTheme;
}

function getCurrentPath(pathname: string): string {
  return "/" + pathname.split("/")[1];
}

function SideNavigation(props: IProps) {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const handleMenuClick: MenuClickEventHandler = ({ key }) => {
    props.history.push(key as string);
    setSelectedKeys([key as string]);
  };

  useEffect(() => {
    setSelectedKeys([getCurrentPath(props.location.pathname)]);
  }, [props.location.pathname]);

  return (
    <Menu
      theme={props.theme}
      mode="inline"
      selectedKeys={selectedKeys}
      defaultSelectedKeys={["/"]}
      onClick={handleMenuClick}
    >
      {props.routes.map((route) => {
        return (
          <Menu.Item key={route.path}>
            {route.icon}
            <span>{route.title}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

export default withRouter(SideNavigation);
