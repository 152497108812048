import React, { useCallback, ReactNode } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  ArrowLeftOutlined,
  LogoutOutlined,
  UserOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Modal, Avatar, PageHeader, Space } from "antd";

import firebase from "firebase/app";
import "firebase/auth";

interface IProps extends RouteComponentProps {
  collapsed: boolean;
  title: ReactNode;
  toggleSidebar: () => void;
  children: ReactNode;
  user: firebase.User | null;
}

function Header(props: IProps) {
  const goBack = useCallback(() => {
    props.history.goBack();
  }, [props.history]);

  const onLogout = useCallback(() => {
    Modal.confirm({
      title: "Do you want to logout!",
      okText: "Logout",
      okType: "primary",
      async onOk() {
        try {
          await firebase.auth().signOut();
        } catch (error) {
          console.log(error);
        }
      },
    });
  }, []);

  const user = props.user;
  return (
    <div style={{ backgroundColor: "white" }}>
      <PageHeader
        ghost
        style={{
          paddingBottom: 0,
        }}
        title={
          <Space
            style={{ display: "flex", alignItems: "center" }}
            direction="horizontal"
            size={12}
          >
            <Button
              type="text"
              onClick={props.toggleSidebar}
              icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuOutlined />}
            />
            <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />} />
            {props.title}
          </Space>
        }
        extra={[
          user && (
            <span key="user" style={{ paddingRight: 16 }}>
              <Avatar
                size={24}
                src={user.photoURL || undefined}
                icon={<UserOutlined />}
              />
              <span style={{ fontWeight: "bold" }}>
                {user && (user.displayName || user.email)}
              </span>
            </span>
          ),
          <Button key="logout" icon={<LogoutOutlined />} onClick={onLogout} />,
        ]}
      >
        <div style={{ paddingTop: 4 }}>{props.children}</div>
      </PageHeader>
    </div>
  );
}

export default withRouter(Header);
