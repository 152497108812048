import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import Loading from "./components/Base/Loading";
import { AppWrapper, AppHeader, AppSider } from "./components/Base/Layout";
import { SideNavigation, TopNavigation } from "./components/Base/Navigation";

import { getRoutes, IRouteConfig, renderRoute } from "./config/routes";
import Logo from "./components/Base/Logo";
import { RouteAuthProps } from "./components/Auth/RoleRoute";

function App(props: RouteAuthProps) {
  const theme = "dark";
  const [loading, setLoading] = useState<boolean>(true);
  const [routes, setRoutes] = useState<IRouteConfig[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    getRoutes({ userRole: props.userRole })
      .then((routes) => {
        if (mounted) {
          setRoutes(routes);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      mounted = false;
    };
  }, [props.userRole]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {loading && <Loading />}
      <AppSider
        collapsible
        breakpoint="lg"
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        theme={theme}
      >
        <Logo style={{ padding: 16, maxWidth: 96, margin: "0 auto" }} />
        <SideNavigation routes={routes} theme={theme} />
      </AppSider>
      <Layout>
        <AppHeader
          user={props.auth}
          collapsed={collapsed}
          toggleSidebar={() => setCollapsed(!collapsed)}
          title={
            <div>
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    render={() => {
                      return route.title;
                    }}
                    exact={route.exact}
                  />
                ))}
              </Switch>
            </div>
          }
        >
          <Switch>
            {routes.map((route) => {
              const { tabs } = route;
              if (!tabs) {
                return null;
              }
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  render={() => <TopNavigation routes={tabs} theme={"light"} />}
                  exact={route.exact}
                />
              );
            })}
          </Switch>
        </AppHeader>
        <Layout.Content style={{ display: "flex", padding: "24px 24px 24px" }}>
          <AppWrapper>
            <Switch>{routes.map((route) => renderRoute(route))}</Switch>
          </AppWrapper>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default App;
