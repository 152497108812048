import React, { ReactNode } from "react";
import { Route } from "react-router-dom";
import { IRouteConfig } from ".";

export function renderRoute(route: IRouteConfig): ReactNode {
  if (!route.nested) {
    return (
      <Route
        key={route.path}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    );
  }

  if (!route.tabs) {
    return null;
  }

  return route.tabs.map((route) => {
    return renderRoute(route);
  });
}
