import React from "react";
import { DashboardOutlined, OrderedListOutlined } from "@ant-design/icons";

export { renderRoute } from "./utils";

export interface IRouteConfig {
  title?: string;
  component?: (props: any) => JSX.Element;
  exact?: boolean;
  path: string;
  icon: React.ReactNode;
  tabs?: IRouteConfig[];
  nested?: boolean;
  hidden?: boolean;
  userRole?: string[];
}

interface RouteConfigOptions {
  userRole: string;
}
export const getRoutes = async (
  options: RouteConfigOptions
): Promise<IRouteConfig[]> => {
  try {
    return [
      {
        title: "Dashboard",
        component: () => <div>Dashboard</div>,
        exact: true,
        path: "/",
        icon: <DashboardOutlined />,
        userRole: ["admin"],
      } as IRouteConfig,
      {
        title: "Example",
        path: "/example",
        userRole: ["admin"],
        icon: <OrderedListOutlined />,
        nested: true,
        exact: false,
        tabs: [
          {
            title: "Example",
            path: "/example",
            exact: true,
            icon: <OrderedListOutlined />,
            component: () => <div>Example</div>,
          },
          {
            title: "Example Tab",
            component: () => <div>Example Tab</div>,
            path: "/example/tab",
            exact: true,
            icon: <OrderedListOutlined />,
            userRole: ["admin"],
          },
        ],
      } as IRouteConfig,
    ]
      .map((route) => {
        const newRoute = { ...route };
        if (newRoute.tabs) {
          newRoute.tabs = newRoute.tabs.filter((route) => {
            if (!route.userRole && options.userRole === "admin") {
              return true;
            }
            return route.userRole && route.userRole.includes(options.userRole);
          });
        }
        return newRoute;
      })
      .filter((route) => {
        if (!route.userRole && options.userRole === "admin") {
          return true;
        }
        return route.userRole && route.userRole.includes(options.userRole);
      });
  } catch (e) {
    throw e;
  }
};
