import React, { ComponentClass, FunctionComponent } from "react";
import { Route, RouteProps } from "react-router-dom";

import Loading from "../Base/Loading";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import { User } from "firebase/app";

export interface RouteAuthProps extends RouteProps {
  auth: User;
  userRole: string;
}

interface Props extends RouteProps {
  component: ComponentClass<RouteAuthProps> | FunctionComponent<RouteAuthProps>;
  role: string | string[];
}

function RoleRoute(props: Props) {
  const { user, loading, userRole } = useFirebaseAuth(props.role);

  const { component: Component, ...rest } = props;
  if (loading) {
    return <Loading />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return <div>Unathorized!</div>;
        }
        return <Component {...props} auth={user} userRole={userRole} />;
      }}
    />
  );
}

export default RoleRoute;
