import React, { ComponentClass, FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import Loading from "../Base/Loading";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import { User } from "firebase/app";

interface InnerProps extends RouteProps {
  auth: User;
}

interface Props extends RouteProps {
  component: ComponentClass<InnerProps> | FunctionComponent<InnerProps>;
}

function PrivateRoute(props: Props) {
  const { user, loading } = useFirebaseAuth();

  const { component: Component, ...rest } = props;
  if (loading) {
    return <Loading />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return <Component {...props} auth={user} />;
      }}
    />
  );
}

export default PrivateRoute;
