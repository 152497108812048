import { useState, useEffect } from "react";

import firebase, { User } from "firebase/app";
import "firebase/auth";
import { message } from "antd";

const allowedRoles = ["admin"];

function getRoles(role: string | string[]) {
  if (typeof role === "string") {
    return [role];
  }
  return role;
}

function useFirebaseAuth(role: string | string[] = allowedRoles) {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<firebase.auth.IdTokenResult | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let mounted = true;
    const unregister = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        if (!getRoles(role).includes(token.claims.role)) {
          await firebase.auth().signOut();
          message.error("You are not authorized to proceed!", 3);
          return;
        }
        setToken(token);
      }
      setTimeout(() => {
        if (mounted) {
          setUser(user);
          setLoading(false);
        }
      }, 0);
    });
    return () => {
      mounted = false;
      console.log("unregister useFirebaseAuth");
      unregister();
    };
  }, [role]);

  return { user, loading, userRole: token ? token.claims.role : null };
}

export default useFirebaseAuth;
