import React from "react";
import { Layout } from "antd";
import { SiderProps } from "antd/lib/layout";

interface IProps extends SiderProps {}
function AppSider(props: IProps) {
  return (
    <div>
      <Layout.Sider
        collapsible={props.collapsible}
        breakpoint={props.breakpoint}
        collapsed={props.collapsed}
        trigger={null}
        tabIndex={-1}
        style={{ opacity: 0, pointerEvents: "none", background: "white" }}
      ></Layout.Sider>
      <Layout.Sider
        collapsible={props.collapsible}
        breakpoint={props.breakpoint}
        collapsed={props.collapsed}
        onCollapse={props.onCollapse}
        trigger={null}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.children}
      </Layout.Sider>
    </div>
  );
}

AppSider.__ANT_LAYOUT_SIDER = true;

export default AppSider;
