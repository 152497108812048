import React, { ComponentClass, FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import Loading from "../Base/Loading";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";

interface Props extends RouteProps {
  component: ComponentClass<RouteProps> | FunctionComponent<RouteProps>;
  role?: string | string[];
}

function GuestRoute(props: Props) {
  const { user, loading } = useFirebaseAuth(props.role);
  const { component: Component, ...rest } = props;
  if (loading) {
    return <Loading />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

export default GuestRoute;
